
/**
 * Main Sharer module
 */
(function() {
  /**
   * Main render function that deals with all HTML templating
   * @param {Array} options
   * @return {String}
   */
  const renderHTML = function(options) {
    /**
     * Simple check for mobile device - should cover 95% of mobile devices
     * which is good enough for this functionality
     *
     * @return {Boolean}
     */
    const isMobile = function() {
      // eslint-disable-next-line max-len
      return /Mobile|Android|iPhone|iPad|iPod|BlackBerry|Windows Phone/i.test(navigator.userAgent);
    };

    const getMobileListItem = function(o) {
      const makeMobileClasses = function(i, iconName) {
        const classArray = [
          'sharer-mobile-item',
          `sharer-mobile-item-var${i}`,
          'sa-icon',
          `sa-icon-${iconName}`,
        ];

        return classArray.join(' ');
      };

      return `  <li>
      <a tabindex="-1" class="${ makeMobileClasses(o.varNumber, o.iconClass) }"
      aria-label="${ o.ariaLabel }"
      href="${o.url}"
      target="_blank"></a>
    </li>`;
    };

    /**
     * Generate an array of HTML for mobile
     *
     * @returns {Array}
     */
    const mobileOptions = options.map((o) => {
      const mobile = isMobile();
      if (o.mobileOnly && mobile === false) {
        return '';
      } else {
        return getMobileListItem(o);
      }
    });

    const getDesktopListItem = function(o) {
      return `  <a href="${ o.url }"
      class="sharer-tab-item sharer-tab-item-var${ o.varNumber }"
      aria-label="${ o.ariaLabel }"
      target="_blank">
        <i class="sa-icon sa-icon-${ o.iconClass }"></i>
      </a>
      `;
    };

    /**
     * Generate an array of HTML for desktop
     *
     * @returns {Array}
     */
    const desktopOptions = options.map((o) => {
      if (o.desktop) {
        return getDesktopListItem(o);
      } else {
        return '';
      }
    });

    const getMainHtml = function(mobileHTML, desktopHTML) {
      const html =
        `
    <aside id="sharer" class="sharer">
      <div class="sharer-mobile-wrapper">
        <button aria-label="Open share options" class="sharer-mobile-btn-open">
          <i class="sa-icon sa-icon-share"></i>
        </button>
      </div>
      <div class="sharer-mobile">
        <ul class="sharer-mobile-list">
          <li>
            <button tabindex="-1"
              aria-label="Close share options"
              class="sharer-mobile-btn-close sharer-mobile-item sa-icon sa-icon-cancel">
            </button>
          </li>
          ${ mobileHTML }
        </ul>
      </div>
      <div class="sharer-tab">
        ${ desktopHTML }
      </div>
    </aside>
      `;

      return html;
    };

    return getMainHtml(
        mobileOptions.join('\n'),
        desktopOptions.join('\n'));
  };

  /**
   * Simple debounce function
   *
   * @param {Function} func
   * @param {Number} delay
   * @return {Function}
   */
  function debounce(func, delay) {
    let timeoutId;

    return function(...args) {
      clearTimeout(timeoutId);

      timeoutId = setTimeout(() => {
        func.apply(this, args);
      }, delay);
    };
  }

  /**
   * Get an array of options for the sharer
   *
   * @param {String} currentUrl
   * @param {String} title
   * @return {Array}
   */
  const getShareOptions = function(currentUrl, title) {
    return [
      {
        'url': `https://www.facebook.com/sharer.php?u=${currentUrl}`,
        'varNumber': '1',
        'iconClass': 'facebook',
        'ariaLabel': 'Share on Facebook',
        'desktop': true,
      },
      {
        'url': `https://twitter.com/share?url=${currentUrl}&text=${title}`,
        'varNumber': '2',
        'iconClass': 'twitter-x',
        'ariaLabel': 'Share on Twitter',
        'desktop': true,
      },
      {
        'url': `mailto:?subject=${title}&amp;body=${currentUrl}`,
        'varNumber': '3',
        'iconClass': 'mail-alt',
        'ariaLabel': 'Share via Email',
        'desktop': true,
      },
      {
        'url': `https://www.linkedin.com/shareArticle?mini=true&url=${currentUrl}`,
        'varNumber': '4',
        'iconClass': 'linkedin',
        'ariaLabel': 'Share on linked in',
        'desktop': true,
      },
      {
        'url': `whatsapp://send?text=${currentUrl}`,
        'varNumber': '5',
        'iconClass': 'whatsapp',
        'ariaLabel': 'Share via Whatsapp',
        'desktop': false,
        'mobileOnly': true,
      },
      {
        'url': `fb-messenger://share/?link=${currentUrl}`,
        'varNumber': '6',
        'iconClass': 'facebook-messenger',
        'ariaLabel': 'Share via Facebook Messenger',
        'desktop': false,
        'mobileOnly': true,
      },
    ];
  };

  const currentUrl = window.location.href;
  const title = encodeURI(document.title);

  /**
   * Get the list of options to build the links from
   *
   * @return {Array} returns an array of objects
   */
  const options = getShareOptions(currentUrl, title);

  /**
   * Insert the share app into the page
   */
  document.querySelector('body').insertAdjacentHTML(
      'beforeend', renderHTML(options));

  // define elements after we have inserted the HTML into the page
  const mobileShare = document.querySelector('.sharer-mobile');
  const btnOpener = document.querySelector('.sharer-mobile-btn-open');
  const mobileShareWrapper = document.querySelector('.sharer-mobile-wrapper');
  const mobileShareItems = mobileShare.querySelectorAll('.sharer-mobile-item');

  /**
   * Add click event to the mobile share
   */
  btnOpener.addEventListener('click', function() {
    mobileShare.classList.add('sharer-mobile-active');
    mobileShareItems.forEach((el) => {
      el.tabIndex = '0';
    });
  });

  document.querySelector('.sharer-mobile-btn-close')
      .addEventListener('click', function() {
        mobileShare.classList.remove('sharer-mobile-active');
        mobileShareItems.forEach((el) => {
          el.tabIndex = '-1';
        });
      });

  /**
   * Add scroll listener to show/ hide the share opener
   */
  const shareappScrollListener = debounce(function() {
    if (window.scrollY > 101 || window.pageYOffset > 101) {
      mobileShareWrapper.classList.add('sharer-mobile-show');
      // mobileShareWrapper.classList.remove('sharer-mobile-close');
    }

    if (window.scrollY < 101 || window.pageYOffset < 101) {
      // mobileShareWrapper.classList.remove('sharer-mobile-show');
      mobileShareWrapper.classList.add('sharer-mobile-close');
    }
  }, 100);

  document.addEventListener('animationend', (event) => {
    if (event.animationName === 'fadeout') {
      mobileShareWrapper.classList.remove('sharer-mobile-show');
      mobileShareWrapper.classList.remove('sharer-mobile-close');
    }
  });

  window.addEventListener('scroll', shareappScrollListener);
}());

/**
 * CSS Loader module
 */
// (function() {
//   /**
//  *
//  * @return {string} Returns the URL path to the CSS file
//  */
//   const getCssPath = function() {
//     const scripts = Array.from(document.scripts);
//     let script;

//     scripts.forEach((item) => {
//       if (item.src && item.src.split('/').includes('share.js')) {
//         script = item;
//       }
//     });

//     if (script) {
//       return script.src.substring(
//           0, script.src.lastIndexOf('/') + 1) + 'share-alt.css';
//     } else {
//       return false;
//     }
//   };

//   const cssPath = getCssPath();

//   if (cssPath) {
//     const cssFile = document.createElement('link');
//     cssFile.setAttribute('rel', 'stylesheet');
//     cssFile.setAttribute('href', getCssPath());
//     document.head.appendChild(cssFile);
//   }
// })();
